import React from 'react';

import imageSaveMoneyBanner from '../../assets/images/illustrations/home-save-money-banner.png';
import imageSaveMoneyBannerMobile from '../../assets/images/illustrations/home-save-money-banner-mobile.png';

import imageIconMonitoring from '../../assets/images/icons/cards/24x7 Monotoring.png';
import imageIconLenders from '../../assets/images/icons/cards/90+ lenders.png';
import imageIconFCA from '../../assets/images/icons/cards/FCA Approved.png';
import imageIconSaveThousands from '../../assets/images/icons/cards/Save thousands of pounds.png';
import imageIconJargonFree from '../../assets/images/icons/cards/Jargon-free.png';

import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import { MainDescription, SecondaryDescription, SecondaryTitle } from '../../components/Typography';
import { useCallback } from 'react';
import CustomImage from '../../components/Image/Image';

const benefitsItems = [
  {
    title: (
      <>
        24/7 deal<br /> monitoring
      </>
    ),
    image: imageIconMonitoring,
  },
  {
    title: (
      <>
        Access to 170+ lenders
      </>
    ),
    image: imageIconLenders,
  },
  {
    title: 'Free independent advice',
    image: imageIconFCA,
  },
  {
    title: 'Free mortgage advice',
    image: imageIconSaveThousands,
  },
  {
    title: (
      <>
        Jargon free
      </>
    ),
    image: imageIconJargonFree,
  },
];

const SwitchingWithUsCouldSaveYou300AMonthSection = ({ isMobile, isTablet }) => {
  const BenefitItem = ({ title, image }) => (
    <div className="benefits__item">
      <div className="benefits__item-image">
        <CustomImage src={image} />
      </div>
      <SecondaryTitle>{title}</SecondaryTitle>
    </div>
  );

  const generateBenefitItems = useCallback(() => {
    const components = [];

    benefitsItems.forEach((el, index) => {
      components.push(<BenefitItem key={index} title={el.title} image={el.image} />);
    });

    return components;
  }, []);

  return (
    <section className="fifth-section">
      <SectionWrapper>
        <div className="benefits-heading">
          <SecondaryTitle>
            Stay on the best {isMobile ? '' : <br />} deal when you remortgage
          </SecondaryTitle>
          <div className="benefits__wrapper">
            <div className="benefits__container">{generateBenefitItems()}</div>
          </div>
        </div>
        {!isMobile && <span className="separator" />}
        <CustomHeadingSection
          leftContent={
            <div className="text-container">
              <div className="text-container__title">
                <SecondaryTitle>Remortgaging the easy way.</SecondaryTitle>
                <MainDescription>
                  Sprive will challenge your mortgage against the market every day to help you find
                  your next deal. We have access to expert advisors who take care of everything,
                  every step of the way.
                </MainDescription>
              </div>
              {isMobile ? (
                <CustomImage src={isMobile ? imageSaveMoneyBannerMobile : imageSaveMoneyBanner} />
              ) : null}
              <div className="difference__container">
                <SecondaryTitle>How we’re different from:</SecondaryTitle>
                <div className="difference__item">
                  <MainDescription>Comparison Sites</MainDescription>
                  <SecondaryDescription>
                    Shows you a lot of deals, but doesn’t tell you if you’re eligible and you’ll
                    need to apply yourself.
                  </SecondaryDescription>
                </div>
                <div className="difference__item">
                  <MainDescription>Your Lender</MainDescription>
                  <SecondaryDescription>
                    Will only show you their deals, which might not be the cheapest in the market.
                  </SecondaryDescription>
                </div>
                <div className="difference__item">
                  <MainDescription>Traditional Brokers</MainDescription>
                  <SecondaryDescription>
                    Often charge fees and are unable to scan the market every day for better deals.
                  </SecondaryDescription>
                </div>
              </div>
            </div>
          }
          rightContent={
            !isMobile && (
              <CustomImage src={isMobile ? imageSaveMoneyBannerMobile : imageSaveMoneyBanner} />
            )
          }
        />
      </SectionWrapper>
    </section>
  );
};

export default SwitchingWithUsCouldSaveYou300AMonthSection;
